import textBalancer from 'text-balancer';
import {
    useEffect
} from 'react';

const TextBalancer = () => {
    useEffect(() => {
        textBalancer.balanceText();
    });
    return null;
}

export default TextBalancer;